// App.js
import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook, faTools, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import headerImage from './logo.png';
import Vector1 from './Vector1.png';
import Vector2 from './Vector2.png';
import Vector3 from './Vector3.png';
import Vector4 from './Vector4.png';
import Vector5 from './Vector5.png';
import Vector6 from './Vector6.png';
import Vector7 from './Vector7.png';
import Foto from './foto.png';

library.add(faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook, faTools, faUser );

const ScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const SobrePage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Sobre</h2>
    <div style={{ textAlign: 'center' }}><img src={Foto} style={{ width: '200px', marginTop: '20px', borderRadius: '7px' }} /></div>
    <p>Dr. Rodrigo Maciel, renomada cirurgião plástico, é especialista em procedimentos estéticos avançados, com ênfase em lipoescultura, abdominoplastia, mastopexia e mamoplastia. Graduado em Medicina pela Universidade Federal, concluiu sua residência em Cirurgia Plástica em um dos principais centros médicos do país. Com uma abordagem cuidadosa e personalizada, Dr. Rodrigo Maciel tem se destacado na prática clínica, oferecendo aos seus pacientes resultados estéticos notáveis e naturalmente harmônicos. Sua dedicação à pesquisa e aprimoramento constante a mantêm atualizada nas mais recentes técnicas e tecnologias no campo da cirurgia plástica. Além disso, seu compromisso com o bem-estar e a segurança dos pacientes a coloca como uma referência respeitável na comunidade médica. Com uma abordagem compassiva e profissional, Dr. Rodrigo Maciel continua a transformar vidas, ajudando seus pacientes a alcançarem a melhor versão de si mesmos.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const ContatoPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
     <h2 style={{ textAlign: 'center' }}>Contato</h2>
    <p>Estou aqui para atender às suas necessidades de maneira eficiente e direcionada. Conte com um atendimento dedicado, adaptado às suas preferências e metas, para garantir uma experiência satisfatória.</p>
    <div style={{ textAlign: 'center', margin: '40px 0px 0px 0px' }}><Link to="https://api.whatsapp.com/send?phone=556230880807" target="_blank" className="red-link">Clique aqui e fale comigo</Link></div>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const LipoesculturaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Lipoescultura</h2>
    <p>A lipoescultura é um procedimento estético que visa remodelar o contorno corporal através da remoção de depósitos de gordura indesejados. Realizada por meio de pequenas incisões, a técnica permite esculpir áreas específicas do corpo, como abdômen, flancos e coxas, proporcionando uma silhueta mais harmoniosa. Ao aspirar o excesso de gordura, a lipoescultura oferece resultados visíveis e duradouros, contribuindo para a autoestima e confiança do paciente.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const AbdominoplastiaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Abdominoplastia</h2>    
    <p>A abdominoplastia, conhecida como cirurgia de abdomên, é um procedimento cirúrgico popular no campo da estética que visa melhorar a aparência do abdômen. Durante a intervenção, o excesso de pele e gordura abdominal é removido, e os músculos abdominais podem ser apertados para criar uma barriga mais firme e tonificada. Indicada principalmente para pessoas que perderam peso significativo ou tiveram gestações, a abdominoplastia pode proporcionar não apenas um contorno abdominal mais esculpido, mas também melhorar a autoconfiança e a qualidade de vida do paciente.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const MastopexiaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Mastopexia</h2>
    <p>A mastopexia é um procedimento cirúrgico estético projetado para elevar e remodelar os seios, restaurando a firmeza e o contorno perdidos devido a fatores como envelhecimento, gravidez ou perda de peso significativa. Durante a intervenção, o excesso de pele é removido e os tecidos mamários são reposicionados para criar seios mais firmes e rejuvenescidos. A mastopexia não apenas melhora a estética dos seios, mas também pode proporcionar um aumento da autoestima e confiança para as mulheres que buscam restaurar a aparência jovem e natural da região mamária.</p>    
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const MamoplastiaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Mamoplastia</h2>
    <p>A mamoplastia é um termo geral que abrange procedimentos cirúrgicos destinados a modificar a forma e o tamanho dos seios. Isso inclui tanto a mamoplastia de aumento, que envolve o uso de implantes para aumentar o volume mamário, quanto a mamoplastia redutora, que visa reduzir o tamanho dos seios para aliviar desconfortos físicos e melhorar a estética. A escolha entre essas opções dependerá das necessidades e objetivos individuais da paciente. A mamoplastia é uma solução personalizada para mulheres que desejam realçar ou modificar a aparência de seus seios, proporcionando não apenas benefícios estéticos, mas também melhorando a autoconfiança e a qualidade de vida.</p>    
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const ProtesePage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Prótese</h2>
    <p>A prótese mamária é um dispositivo utilizado em procedimentos de mamoplastia de aumento para aumentar o volume e melhorar a forma dos seios. Geralmente feitas de silicone ou solução salina, as próteses são escolhidas com base nas preferências da paciente e nas recomendações do cirurgião. Esse tipo de intervenção é procurado por mulheres que desejam realçar ou restaurar o volume dos seios, seja por razões estéticas ou após eventos como a amamentação ou perda de peso. A prótese mamária oferece uma opção personalizada para alcançar os objetivos desejados, proporcionando resultados duradouros e uma melhoria significativa na autoestima e na satisfação com a aparência física.</p>    
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const App = () => (
  <Router>
    <div>
      <header style={{ textAlign: 'center' }}>
      <Link to="/"><img src={headerImage} style={{ width: '150px', marginTop: '20px' }} /></Link>
      </header>
      
      
      
      <Routes>
      
          <Route path="/" element={

<div>
  
  <h2 style={{ textAlign: 'center' }}>Transformando sonhos em realidade</h2>
          
          <div className="container">
        
           
              <div className="item">
                <Link to="/lipoescultura" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector1} style={{ width: '50px' }} />
                  </div>
                  Lipoescultura
                </Link>
              </div>
              <div className="item">
                <Link to="/abdominoplastia" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector2} style={{ width: '50px' }} />
                  </div>
                  Abdominoplastia
                </Link>
              </div>
              <div className="item">
                <Link to="/mastopexia" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector3} style={{ width: '50px' }} />
                  </div>
                  Mastopexia
                </Link>
              </div>        
              <div className="item">
                <Link to="/mamoplastia" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector4} style={{ width: '50px' }} />
                  </div>
                  Mamoplastia
                </Link>
              </div>
              <div className="item">
                <Link to="/protese" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector5} style={{ width: '50px' }} />
                  </div>
                  Prótese
                </Link>
              </div>
              <div className="item">
                <Link to="/sobre" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector6} style={{ width: '50px' }} />
                  </div>
                  Sobre
                </Link>
              </div>
              <div className="item">
                <Link to="/contato" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Vector7} style={{ width: '50px' }} />
                  </div>
                  Contato
                </Link>
              </div>
              {/* Add more links for other icons as needed */}
            
          
          </div></div>
          } />
          <Route path="/lipoescultura" element={<LipoesculturaPage />} />
          <Route path="/abdominoplastia" element={<AbdominoplastiaPage />} />
          <Route path="/mastopexia" element={<MastopexiaPage />} />
          <Route path="/mamoplastia" element={<MamoplastiaPage />} />
          <Route path="/protese" element={<ProtesePage />} />
          <Route path="/sobre" element={<SobrePage />} />
          <Route path="/contato" element={<ContatoPage />} />
          {/* Add routes for other items as needed */}
      </Routes>
      <footer style={{ textAlign: 'center', padding: '20px', color: 'black'}}>
        Copyright 2024
      </footer>
    </div>
  </Router>
);

export default App;
